import React, { useState, useEffect } from 'react';
import './CartModal.css';
import { useCart } from '../context/CartContext';
import { isMobile, isTablet } from 'react-device-detect';

function CartModal({ isOpen, onClose }) {
    const [isCheckoutStage, setIsCheckoutStage] = useState(false);
    const [isPickupStage, setIsPickupStage] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setIsCheckoutStage(false);
            setIsPickupStage(false); // Reset checkout stage when modal is closed
        }
    }, [isOpen]);

    useEffect(() => {
        if (isCheckoutStage) {
            // Clear form data when entering the checkout stage
            setFormData({
                name: '',
                email: '',
                phone: ''
            });
        }
    }, [isCheckoutStage]); // This hook will run whenever `isCheckoutStage` changes

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });



    const [isOrderSuccess, setIsOrderSuccess] = useState(false); // Track success state

    const { cartItems, removeItemFromCart, updateCartItemQuantity, clearCart } = useCart();

    if (!isOpen) return null;
    // Check if the cart is empty
    const isCartEmpty = cartItems.length === 0 || cartItems.reduce((total, item) => total + item.quantity, 0) === 0;
    // Calculate the grand total of all items in the cart
    const calculateGrandTotal = () => {
        return cartItems.reduce((acc, cartItem) => acc + cartItem.totalPrice, 0).toFixed(2);
    };

    const handleQuantityChange = (itemId, change) => {
        const cartItem = cartItems.find((item) => item.item.id === itemId);
        const newQuantity = cartItem.quantity + change;

        // Ensure quantity does not drop below 1
        if (newQuantity > 0) {
            updateCartItemQuantity(itemId, newQuantity);
        }
    };

    const formatOrderDetails = (cartItems) => {
        let orderMessage = "Hello, I'd like to place the following order:\n\n";

        cartItems.forEach((cartItem, index) => {
            const { item, quantity, selectedRequiredOption, selectedOptionalOptions, totalPrice } = cartItem;
            orderMessage += `Item ${index + 1}:\n`;
            orderMessage += `${item.item_name}: £${parseFloat(item.price).toFixed(2)}\n`;

            if (selectedRequiredOption) {
                orderMessage += `${selectedRequiredOption.option_name}: +£${parseFloat(selectedRequiredOption.option_price).toFixed(2)}\n`;
            }

            if (selectedOptionalOptions.length > 0) {
                selectedOptionalOptions.forEach(option => {
                    orderMessage += `${option.option_name}: +£${parseFloat(option.option_price).toFixed(2)}\n`;
                });
            }

            orderMessage += `Quantity: ${quantity}\n`;
            orderMessage += `Price: £${parseFloat(totalPrice).toFixed(2)}\n\n`;
        });

        orderMessage += `Total Order Price: £${calculateGrandTotal()}`;
        return orderMessage;
    };

    function isMobileDevice() {
        // Check if the screen width is mobile-sized (<=768px)
        const isMobileWidth = window.innerWidth <= 768;

        // Check if the device is detected as mobile or tablet using react-device-detect
        const isMobileUserAgent = isMobile || isTablet;

        // Return true if both conditions are met: mobile width and mobile user agent
        return isMobileWidth && isMobileUserAgent;
    }


    const handleCheckout = () => {
        setIsPickupStage(true); // Move to the pickup address stage first
    };

    // Function to confirm pickup and proceed to WhatsApp or form stage
    const confirmCheckout = () => {
        if (isMobileDevice()) {
            const orderDetails = formatOrderDetails(cartItems);
            const whatsappUrl = `https://wa.me/447368325136?text=${encodeURIComponent(orderDetails)}`;
            window.open(whatsappUrl, "_blank");
            clearCart();
            onClose();
        } else {
            setIsPickupStage(false); // Exit pickup stage
            setIsCheckoutStage(true); // Move to checkout form stage
        }
    };



    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const orderDetails = formatOrderDetails(cartItems); // The formatted order details

        const formDataObj = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            message: orderDetails,  // Order details included in the message
        };

        try {
            const response = await fetch('https://lkofoods.com/php/send-email.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formDataObj),
            });

            const result = await response.json();
            if (result.success) {
                setIsOrderSuccess(true); // Set success state to true
                clearCart(); // Clear the cart after success
                setIsCheckoutStage(false); // Go back to the initial cart view

                // Automatically close the modal after 4 seconds
                setTimeout(() => {
                    setIsOrderSuccess(false); // Reset success state
                    onClose(); // Close the modal
                }, 4000);
            } else {
                alert('Failed to place the order. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Something went wrong. Please try again.');
        }
    };

    return (
        <div className="cart-modal-overlay">
            <div className="cart-modal-content">
                <div className="cart-modal-top">
                    <h2>Your Cart</h2>
                    <button className="close-modal-button" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                {!isCheckoutStage && !isPickupStage && cartItems.length > 0 && (
                    <button
                        className="clear-cart-button"
                        onClick={clearCart}
                    >
                        Clear Cart
                    </button>
                )}

                {isOrderSuccess ? (
                    // Success Message (Stage 4)
                    <div className="order-success">
                        <i className="fas fa-check-circle"></i>
                        <h3>Your order has been received.</h3>
                        <p>Please check your email for details.</p>
                    </div>
                ) : isPickupStage ? (
                    <>
                        {/* Pickup Address (Stage 2) */}
                        <div className="pickup-stage">
                            <div className='pickup-top'>
                                <i onClick={() => setIsPickupStage(false)} className="fas fa-arrow-left"></i>
                                <h3>Pickup Address</h3>
                            </div>
                            <div className="pickup-address">
                            <i className="fas fa-map-marker-alt"></i> 
                                <p>34, Wellington Road, <br></br> Salford M30 0NP</p>
                            </div>
                            <button className="add-to-cart-button" onClick={confirmCheckout}>
                                Confirm Checkout
                            </button>
                        </div>
                    </>
                ) : !isCheckoutStage ? (
                    <>
                        {/* Cart View (Stage 1) */}
                        {cartItems.length === 0 ? (
                            <p className="empty-cart">Your cart is empty</p>
                        ) : (
                            <ul className="cart-items-list">
                                {cartItems.map((cartItem, index) => {
                                    const { item, quantity, selectedRequiredOption, selectedOptionalOptions, totalPrice } = cartItem;
                                    return (
                                        <li key={index} className="cart-item">
                                            <div className='cart-item-main'>
                                                {item.image && (
                                                    <img src={`https://admin.lkofoods.com/uploads/${item.image}`} alt={item.item_name} className="cart-item-image" />
                                                )}
                                                <div className="cart-item-details">
                                                    <div className='cart-item-details-top'>
                                                        <h3>{item.item_name}</h3>
                                                        <p>£{parseFloat(item.price).toFixed(2)}</p>
                                                    </div>

                                                    {selectedRequiredOption && (
                                                        <div className="cart-option">
                                                            <span className="option-name">{selectedRequiredOption.option_name}</span>
                                                            <span className="option-price">+ £{parseFloat(selectedRequiredOption.option_price).toFixed(2)}</span>
                                                        </div>
                                                    )}

                                                    {selectedOptionalOptions.length > 0 && (
                                                        <div className="cart-options-list">
                                                            {selectedOptionalOptions.map((option, idx) => (
                                                                <div key={idx} className="cart-option">
                                                                    <span className="option-name">{option.option_name}</span>
                                                                    <span className="option-price">+ £{parseFloat(option.option_price).toFixed(2)}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}

                                                    <div className='cart-bottom'>
                                                        <div className="quantity-controls">
                                                            <button className="cart-quantity-btn" onClick={() => handleQuantityChange(item.id, -1)}><i className="fas fa-minus"></i></button>
                                                            <span>{quantity}</span>
                                                            <button className="cart-quantity-btn" onClick={() => handleQuantityChange(item.id, 1)}><i className="fas fa-plus"></i></button>
                                                        </div>
                                                        <p className='cart-item-total'>£{parseFloat(totalPrice).toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cart-item-actions">
                                                <button className="delete-btn" onClick={() => removeItemFromCart(item.id)}>
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <div className="checkout-section">
                            <h3>Total: £{calculateGrandTotal()}</h3>
                            <button className="add-to-cart-button" disabled={isCartEmpty} onClick={handleCheckout}>
                                Checkout
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        {/* Checkout Form (Stage 3) */}
                        <div className="checkout-form">
                            <div className='checkout-form-top'>
                                <i onClick={() => setIsPickupStage(true)} className="fas fa-arrow-left"></i>
                                <h3>Complete Your Order</h3>
                            </div>
                            <form onSubmit={handleFormSubmit}>
                                <input
                                    type="text"
                                    placeholder="Your Name"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    required
                                />
                                <input
                                    type="email"
                                    placeholder="Your Email"
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    required
                                />
                                <input
                                    type="tel"
                                    placeholder="Your Phone Number"
                                    value={formData.phone}
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                    required
                                />
                                <button type="submit" className="add-to-cart-button">Place Order</button>
                            </form>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
}

export default CartModal;
