import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">

                {/* About Us Section */}
                <div className="footer-section footer-about">
                    <h3>About LKO Restaurant</h3>
                    <p>
                        At LKO Restaurant, we are dedicated to serving delicious, high-quality meals made with the freshest ingredients. Whether you're here for a quick bite or a full-course meal, our commitment to flavor and service remains unmatched.
                    </p>
                </div>



                {/* Our Menu Section */}
                <div className="footer-section">
                    <h3>Our Menu</h3>
                    <ul className="menu-links">
                        <li><a href="/order-online">Starters</a></li>
                        <li><a href="/order-online">Main Menu</a></li>
                        <li><a href="/order-online">Sides and Extra</a></li>
                        <li><a href="/order-online">House Special</a></li>
                        <li><a href="/order-online">Saturday Special</a></li>
                        <li><a href="/order-online">Pre-Order</a></li>
                        <li><a href="/order-online">Non-Alcoholic Drinks</a></li>
                        <li><a href="/order-online">Wine & Champagne</a></li>
                        <li><a href="/order-online">Spirit & Liqueur</a></li>
                    </ul>
                </div>

                {/* Contact Information Section */}
                <div className="footer-section contact-section">
                    <h3>Contact Us</h3>
                    <p>
                        <a href="mailto:info@lkofoods.com">info@lkofoods.com</a>
                    </p>
                    <p>
                        <a href="tel:+447368325136">+44 7368 3215 136</a>, <a href="tel:01616693636">01616693636</a>
                    </p>
                    
                            
                        
                    <p>34, Wellington Road, Salford M30 0NP
                    </p>
                </div>


                {/* Working Hours Section */}
                <div className="footer-section">
                    <h3>Opening Hours</h3>
                    <p>OPEN: </p>
                    <p>Monday - Friday: 1pm - 10pm</p> 
                    <p>Saturday & Bank Holidays: 12pm - 11pm</p> <br></br>
                    <p>CLOSED: Sunday</p>
                </div>


            </div>



            {/* Copyright Section */}
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} LKO Restaurant. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;