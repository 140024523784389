import React, { useState, useEffect } from 'react';
import './OrderPage.css';
import ItemModal from '../components/ItemModal';
import { useCart } from '../context/CartContext';

const OrderPage = () => {
    const [categories, setCategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [openCategories, setOpenCategories] = useState([]);  // Track open categories (start empty)
    const [selectedItem, setSelectedItem] = useState(null);
    const { successMessage, clearSuccessMessage } = useCart();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');  // For storing the search input
    const [filteredMenuItems, setFilteredMenuItems] = useState(menuItems);  // For storing filtered items
    const [filteredCategories, setFilteredCategories] = useState(categories);  // For storing filtered categories

    

    useEffect(() => {
        fetchCategories();
        fetchMenuItems();
    }, []);

    useEffect(() => {
        // Clear the success message after 5 seconds
        if (showSuccessMessage) {
            const timer = setTimeout(() => {
                clearSuccessMessage();
                setShowSuccessMessage(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [showSuccessMessage, clearSuccessMessage]);

    useEffect(() => {
        // Filter the items based on the search query (only by item name)
        const filteredItems = menuItems.filter(item =>
            item.item_name.toLowerCase().includes(searchQuery.toLowerCase())  // Search only by item name
        );

        setFilteredMenuItems(filteredItems);

        // Filter categories based on whether any item in that category matches the search
        const filteredCategories = categories.filter(category =>
            filteredItems.some(item => item.category_id === category.id)
        );

        setFilteredCategories(filteredCategories);

        // Open categories that have matching items
        const openedCategories = filteredCategories.map(category => category.id);
        if (searchQuery) {
            setOpenCategories(openedCategories);
        } else {
            setOpenCategories([]);
        }
    }, [searchQuery, menuItems, categories]);

    const handleSuccess = () => {
        setShowSuccessMessage(true);
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://lkofoods.com/php/categories.php');
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchMenuItems = async () => {
        try {
            const response = await fetch('https://lkofoods.com/php/menu-items.php');
            const data = await response.json();
            setMenuItems(data);
        } catch (error) {
            console.error('Error fetching menu items:', error);
        }
    };

    const handleToggleCategory = (categoryId) => {
        // Toggle category open/close
        setOpenCategories(prevState =>
            prevState.includes(categoryId)
                ? prevState.filter(id => id !== categoryId)
                : [...prevState, categoryId]
        );
    };

    const getMenuItemsByCategory = (categoryId) => {
        return filteredMenuItems.filter(item => item.category_id === categoryId);
    };

    const handleAddToCart = (item) => {
        setSelectedItem(item);
    };

    return (
        <div className="order-page-container">
            {showSuccessMessage && successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}

            <p className='order-notice'>
                We are accepting online pick up orders! Select your food, place your order, pick up from our restaurant and make your payment.
            </p>

            {/* Search input field */}
            <input
                type="text"
                className="search-input"
                placeholder="Search items..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            {/* If no items match search query, show 'No items found' */}
            {searchQuery && filteredMenuItems.length === 0 && (
                <p className='no-items'>No items found</p>
            )}

            {/* Only show categories if there are any matching items */}
            {filteredCategories.length > 0 ? (
    <div className="order-accordion">
        {filteredCategories.map((category) => {

            return (
                <div key={category.id} id={`category-${category.id}`} className="order-accordion-item">
                    <div className="order-accordion-header" onClick={() => handleToggleCategory(category.id)}>
                        <span className="category-name">{category.category_name}</span>
                        <span className="accordion-icon">{openCategories.includes(category.id) ? '-' : '+'}</span>
                    </div>

                    {/* Render special text for category 7 */}
                    {parseInt(category.id) === 7 && openCategories.includes(category.id) && (
                        <div className="special-category-text">
                            <p>Advance booking and payment is required for all Saturday Specials. Please speak to a member of Staff or order online at least by Friday</p>
                        </div>
                    )}
                    {parseInt(category.id) === 6 && openCategories.includes(category.id) && (
                        <div className="special-category-text">
                            <p>Advance booking and payment is required for all Pre-order items.</p>
                        </div>
                    )}


                    {openCategories.includes(category.id) && (
                        <div className="order-accordion-body">
                            <ul className="order-menu-list">
                                {getMenuItemsByCategory(category.id).map((item) => (
                                    <li key={item.id} className="order-menu-item">
                                        <img
                                            src={`https://admin.lkofoods.com/uploads/${item.image}`}
                                            alt={item.item_name}
                                            className="order-menu-item-image"
                                        />
                                        <div className="order-menu-item-details">
                                            <h3>{item.item_name}</h3>
                                            <p className="menu-item-description">{item.item_description}</p>
                                            <div className='menu-item-bottom'>
                                                <p className="menu-item-price">£{item.price}</p>
                                                <div className="add-to-cart-icon" onClick={() => handleAddToCart(item)}>
                                                    <i className="fas fa-shopping-cart cart-btn"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            );
        })}
    </div>
) : null}


            {/* Item Modal */}
            {selectedItem && (
                <ItemModal
                    isOpen={!!selectedItem}
                    item={selectedItem}
                    onClose={() => setSelectedItem(null)}
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );
};

export default OrderPage;
