import React, { useState, useRef, useEffect } from 'react';
import './HomePage.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const formRef = useRef(null);
    const [menuItems, setMenuItems] = useState([]);
    const [reviews, setReviews] = useState([]);  // State for reviews

    // Fetch menu items from the PHP endpoint
    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await fetch('https://lkofoods.com/php/fetch-random-items.php');
                const data = await response.json();
                setMenuItems(data);
            } catch (error) {
                console.error('Error fetching menu items:', error);
            }
        };
        fetchMenuItems();
    }, []);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await fetch('https://lkofoods.com/php/reviews.php'); // Endpoint to fetch reviews
                const data = await response.json();
                setReviews(data); // Store reviews in the state
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        fetchReviews();
    }, []);

    const handleReviewSubmit = async (event) => {
        event.preventDefault();

        const name = event.target.name.value;
        const review = event.target.review.value;
        const star = rating;

        if (star <= 0 || star > 5) {
            alert('Please select a valid star rating.');
            return;
        }

        const reviewData = { name, star, review };

        try {
            const response = await fetch('https://lkofoods.com/php/reviews.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(reviewData),
            });

            const result = await response.json();
            if (result.success) {

                // Reload the page
                window.location.reload();

                // Optionally, scroll to the review section after the page reload
                // You may want to use setTimeout to ensure the page has reloaded before scrolling.
                setTimeout(() => {
                    const reviewSection = document.getElementById('reviewSection'); // Replace with your actual review section ID
                    if (reviewSection) {
                        reviewSection.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 500); // Adjust the delay as necessary
            } else {
                alert(result.message);
            }
        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };


    // Slider settings
    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        pauseOnFocus: false,
        infinite: true,
        arrows: false,
        cssEase: "ease-in-out",
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const handleReviewFormToggle = () => {
        setShowReviewForm(!showReviewForm);
        setRating(0);

        if (!showReviewForm) {
            setTimeout(() => {
                formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 100);
        }
    };

    return (
        <div className="home-page">
            {/* Hero Banner */}
            <section className="hero-banner">
                <div className='hero-banner-content'>
                    <p>WELCOME TO LKO</p>
                    <h2>Where Flavors, Dance and Celebrations come alive.</h2>
                    <h4>We redefine dining with our unique blend of a restaurant, game lounge and party destination, all under one roof</h4>
                    <div className="hero-buttons">
                        <a href='/order-online' className="cta-button primary-btn">Order Now</a>
                        <a href="/book-table" className="cta-button secondary-btn">Make Reservation</a>
                    </div>
                </div>

            </section>

            {/* About Section */}
            <section className="about">
                <div className="about-features">
                    <div className="feature">
                        <i className="fas fa-leaf feature-icon"></i>
                        <h3>Fresh<br></br>Ingredients</h3>
                        <p>Only the finest and freshest ingredients go into our dishes.</p>
                    </div>
                    <div className="feature">
                        <i className="fas fa-utensils feature-icon"></i>
                        <h3>Authentic<br></br>Taste</h3>
                        <p>Enjoy authentic, home-cooked flavors crafted with love.</p>
                    </div>
                    <div className="feature">
                        <i className="fas fa-clock feature-icon"></i>
                        <h3>Convenient<br></br>Pickup</h3>
                        <p>Order online and pick up your food at your convenience.</p>
                    </div>
                </div>
            </section>

            {/* Menu Preview Section */}
            <section className="menu-preview">
                <h2>Taste the Magic 🔥</h2>
                <div className="menu-items">
                    {menuItems.length > 0 ? (
                        <Slider {...settings} className="menu-carousel">
                            {menuItems.map((item) => (
                                <div key={item.id} className="menu-item">
                                    <img
                                        src={`https://admin.lkofoods.com/uploads/${item.image}`}
                                        alt={item.item_name}
                                        className="round-image"
                                    />
                                    <h3 className='dish-name'>{item.item_name}</h3>
                                    <p className='price'>£ {item.price}</p>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
                <a className="cta-button secondary-btn" href='/order-online'>Explore Our Menu</a>
            </section>

            {/* Testimonials Section */}
            <section className="testimonials" id='reviewSection'>
                <h2>Customer Reviews</h2>
                <div className="reviews">
                    {reviews.length > 0 ? (
                        reviews.map((review) => (
                            <div key={review.id} className="review">
                                <div className='review-content'>
                                    <p className='review-text'>"{review.review}"</p>
                                    <p className='review-name'>{review.name}</p>
                                    <div className="stars">
                                        {[...Array(5)].map((_, i) => (
                                            <i
                                                key={i}
                                                className={i < review.star ? "fas fa-star" : "far fa-star"}
                                            ></i>
                                        ))}
                                    </div>

                                </div>


                            </div>
                        ))
                    ) : (
                        <p>No reviews yet</p>
                    )}
                </div>
                <button className="cta-button primary-btn post-review-btn" onClick={handleReviewFormToggle}>
                    Post Review
                </button>

                {/* Review Form */}
                {showReviewForm && (
                    <div className="review-form" ref={formRef}>
                        <h3>Post Your Review</h3>
                        <form onSubmit={handleReviewSubmit}>
                            <label>Name:</label>
                            <input type="text" name="name" required />

                            {/* Star Rating Input */}
                            <label>Star Rating:</label>
                            <div className="star-rating">
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <i
                                        key={star}
                                        className={star <= (hover || rating) ? "fas fa-star" : "far fa-star"} // Filled if hovered or selected
                                        onMouseEnter={() => setHover(star)}  // Set hover state to current star
                                        onMouseLeave={() => setHover(0)}     // Reset hover state when leaving
                                        onClick={() => setRating(star)}      // Set rating state to current star
                                    ></i>
                                ))}
                            </div>

                            <label>Review:</label>
                            <textarea name="review" required rows="5"></textarea>

                            <button type="submit" className='cta-button primary-btn post-review-btn'>Submit Review</button>
                        </form>
                    </div>
                )}

            </section>

        </div>


    );
};

export default HomePage;
