import React, { useState } from 'react';
import './BookTable.css';
import { useNavigate } from 'react-router-dom';
import tableImage from '../assets/book-BG.jpg';

function BookTablePage() {
    const navigate = useNavigate();
    const [dateType, setDateType] = useState("text");
    const [formData, setFormData] = useState({
        seats: "",
        date: "",
        time: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        specialRequest: ""
    });

    const generateTimeOptions = () => {
        const options = [];
        const startHour = 12;
        const endHour = 19;

        for (let hour = startHour; hour <= endHour; hour++) {
            for (let minutes = 0; minutes < 60; minutes += 15) {
                const timeString = new Date(0, 0, 0, hour, minutes)
                    .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                options.push(timeString);
            }
        }
        return options;
    };

    const handleDateFocus = () => setDateType("date");
    const handleDateBlur = (e) => {
        if (!e.target.value) {
            setDateType("text");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://lkofoods.com/php/send-booking.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            if (result.success) {
                navigate('/thank-you');
                setFormData({
                    seats: "",
                    date: "",
                    time: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    specialRequest: ""
                });
            } else {
                alert('Failed to send the booking. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="booktable-container">
            <h2>Book a Table at LKO</h2>
            <img src={tableImage} alt="table-image" className='table-image'></img>
            <form className="booktable-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="number"
                        id="seats"
                        min="1"
                        name="seats"
                        value={formData.seats}
                        onChange={handleChange}
                        placeholder="Number of seats"
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type={dateType}
                        id="date"
                        name="date"
                        value={formData.date}
                        min={new Date().toISOString().split('T')[0]} 
                        onChange={handleChange}
                        placeholder="Select Date"
                        onFocus={handleDateFocus}
                        onBlur={handleDateBlur}
                        required
                    />
                </div>

                <div className="form-group">
                    <select
                        id="time"
                        name="time"
                        value={formData.time}
                        onChange={handleChange}
                        style={{ color: formData.time === "" ? "#777777" : "#000" }}
                        required
                    >
                        <option value="">Select time</option>
                        {generateTimeOptions().map((timeOption, index) => (
                            <option key={index} value={timeOption}>
                                {timeOption}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="First name"
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Last name"
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone number"
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email address"
                        required
                    />
                </div>

                <div className="form-group">
                    <textarea
                        id="specialRequest"
                        name="specialRequest"
                        value={formData.specialRequest}
                        onChange={handleChange}
                        placeholder="Special request"
                        rows="4"
                    ></textarea>
                </div>

                <button type="submit" className="cta-button primary-btn post-review-btn book-btn">
                    Book Now
                </button>
            </form>
        </div>
    );
}

export default BookTablePage;
